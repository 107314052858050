import React from "react"
import { Grid, GridItem, Text } from "@chakra-ui/react"
import WishlistItem from "./WishlistItem"

const WishlistList = ({ items, handleDelete, handleMove, loading }) => (
  <>
    {items?.length ? (
      <Grid templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]} gap={6} maxW="100%">
        {items?.map(item => (
          <GridItem colSpan={1} key={item?.id.toString()}>
            <WishlistItem key={item?.id?.toString()} item={item} handleDelete={handleDelete} handleMove={handleMove} loading={loading} />
          </GridItem>
        ))}
      </Grid>
    ) : loading ? (
      <Text>Loading...</Text>
    ) : (
      <Text>No items</Text>
    )}
  </>
)

export default React.memo(WishlistList)
