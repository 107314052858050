import React from "react"
import { useLocation } from "@reach/router"
import { Box, Button, Link } from "@chakra-ui/react"
import { useWishlist } from "@app/hooks/useWishlist"
import { useWishlistContext } from "@app/providers/wishlist"
import WishlistList from "./WishlistList"

const WishlistCard: React.FC = () => {
  const {
    sharedWishlist,
    getSharedWishlist,
    moveToCart,
    deleteFromWishlist,
    // updateWishlist,
    shareWishlist,
    saveWishlist,
    shareUrl,
    loading,
  } = useWishlist()
  const location = useLocation()
  const { wishlist } = useWishlistContext()

  const shared = location?.search?.includes("id")
  if (shared && !sharedWishlist?.length) getSharedWishlist()

  const handleDelete = async id => await deleteFromWishlist(id)
  const handleMove = async (id, variantId) => await moveToCart(id, variantId)
  // const handleEdit = async item => await updateWishlist(item)
  const handleShare = () => shareWishlist()
  const handleSave = () => saveWishlist()

  const items = shared ? sharedWishlist : wishlist

  return (
    <>
      <WishlistList items={items} handleDelete={handleDelete} handleMove={handleMove} loading={loading} />
      <Box mt={10} display="none">
        {!shared && items?.length > 0 && <Button onClick={() => handleShare()}>Share Wishlist</Button>}
        {shared && <Button onClick={() => handleSave()}>Save Wishlist</Button>}
        {shareUrl && <Link to={shareUrl}>{shareUrl}</Link>}
      </Box>
    </>
  )
}

export default React.memo(WishlistCard)
