import React from "react"
import { Container, Flex, Box, Heading, Text } from "@chakra-ui/react"
import { useContent } from "@app/hooks/useContent"
import type { Props } from "@app/pages/account/wishlist"
import type { PageProps } from "@root/types/global"
import WishlistCard from "./WishlistCard"

const Wishlist: React.FC<PageProps<Props>> = ({ page }) => {
  const content = useContent(page)

  return (
    <Container as="section" maxW="container.xxl" px={[4, 16]} pb={[4, 12]}>
      <Box as="section" py={[4, 8]}>
        <Flex justifyContent="space-between">
          <Box width={["100%", "33%"]}>
            <Heading as="h1" size="h1" textAlign={["left"]} noOfLines={2} maxW={["unset", "440px"]} mb={[0, 4]} mt={[0, -2]}>
              {page?.title}
            </Heading>
          </Box>
          <Box width="66%" display={["none", "block"]}>
            <Text textStyle="paragraph2" size="14" maxWidth="680px">
              {content}
            </Text>
          </Box>
        </Flex>
        <Flex display={["none", "flex"]} justifyContent="space-between">
          <Box width={["100%", "33%"]}></Box>
          <Box width="66%"></Box>
        </Flex>
      </Box>
      <Flex borderBottom="1px solid" borderColor="grey.200" pb={3}></Flex>
      <Flex mt={[4, 8]}>
        <Box w="100%">
          <WishlistCard />
        </Box>
      </Flex>
    </Container>
  )
}

export default Wishlist
