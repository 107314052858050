import React, { useCallback, useState } from "react"
import { AspectRatio, Box, Flex, Link, LinkBox, Image, LinkOverlay, Text, Select, Button, IconButton } from "@chakra-ui/react"
import { useConfigContext } from "@app/providers/config"
import { useRoutes } from "@app/hooks/useRoutes"
import { useCart } from "@app/hooks/useCart"
import { HeartIcon, HeartIconFilled } from "@app/theme/icons"
import { useWishlist } from "@app/hooks/useWishlist"
import ProductPrice from "../Product/ProductPrice"
import { getResponsiveProductImages } from "@app/utils/getProductImages"

const WishlistItem = ({ item, handleDelete, loading }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { urlResolver } = useRoutes()
  const { addToCart, loading: cartLoading } = useCart()
  const { existsInWishlist } = useWishlist()
  const [activeVariant, setActiveVariant] = useState(item.variants?.[0])

  const size = item.options.find((option: any) => option?.name === "Size")
  const link = urlResolver(item, routes.PRODUCT)

  const handleVariant = useCallback(
    ({ target: { value } }) => {
      const variant = item.variants.find(({ title }: { title: string }) => title.includes(value))
      setActiveVariant(variant)
    },
    [item]
  )

  const { desktopImages, mobileImages } = getResponsiveProductImages(item?.images)

  return (
    <LinkBox mb={4}>
      <Flex justifyContent="center" mb={["2", "16px"]}>
        <AspectRatio d={["none", "block"]} ratio={1} w="100%">
          <Image
            src={desktopImages[0]?.src}
            alt={item?.title}
            maxW="100%"
            fallbackSrc="https://plchldr.co/i/300?&amp;bg=EFEFEF&amp;fc=EFEFEF"
          />
        </AspectRatio>
        <AspectRatio d={["block", "none"]} ratio={414 / 532} w="100%">
          <Image
            src={mobileImages[0]?.src}
            alt={item?.title}
            maxW="100%"
            fallbackSrc="https://plchldr.co/i/300?&amp;bg=EFEFEF&amp;fc=EFEFEF"
          />
        </AspectRatio>
        <IconButton
          aria-label="wishlist"
          onClick={() => handleDelete(item?.id)}
          disabled={loading}
          variant="ghost"
          size="lg"
          border="none"
          icon={
            existsInWishlist(item?.id) ? <HeartIconFilled height={"30px"} width={"30px"} /> : <HeartIcon height={"30px"} width={"30px"} />
          }
          position="absolute"
          top={4}
          right={4}
          zIndex="1"
        />
      </Flex>
      <Box textAlign="left">
        <LinkOverlay as={Link} href={link.url} title={item?.title}>
          <Text as="h3" size="14" textStyle="paragraph" mb="0" lineHeight="21px" maxW={["100%", "80%"]}>
            {item?.title}
          </Text>
          <ProductPrice variant={activeVariant} small={true} />
        </LinkOverlay>
      </Box>
      <Box>
        <Flex display="flex" bg={"white"} alignItems="center" pt={4} pb={8} justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Text variant="label">Size: </Text>
            <Select
              variant="unstyled"
              size="xs"
              fontSize="14"
              fontWeight="700"
              ml="2"
              pb="2px"
              isDisabled={loading}
              value={activeVariant.selectedOptions?.find(({ name }: { name: string }) => name === "Size")?.value}
              onChange={handleVariant}
              textDecoration="underline"
              textDecorationColor="#B1B1B1"
              textUnderlineOffset="2px"
            >
              {size?.values.map((value: string, index: number) => (
                <option key={value?.toString() + index} value={value}>
                  {value}
                </option>
              ))}
            </Select>
          </Box>
          <Button
            w={32}
            ml="9"
            onClick={() => addToCart(activeVariant?.id)}
            disabled={!activeVariant?.availableForSale || loading}
            isLoading={cartLoading}
            variant="outline"
            size="sm"
          >
            Add to Cart
          </Button>
        </Flex>
      </Box>
    </LinkBox>
  )
}

export default React.memo(WishlistItem)
